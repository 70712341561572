import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Shani Mirza`}</strong>{` was a student at `}<a parentName="p" {...{
        "href": "/The%20Basentia%20Omnia",
        "title": "The Basentia Omnia"
      }}>{`The Basentia`}</a>{` at the same time as `}<a parentName="p" {...{
        "href": "/Rikkart%20Lauten",
        "title": "Rikkart Lauten"
      }}>{`Rikkart Lauten`}</a>{`, and was with him when the events of `}<a parentName="p" {...{
        "href": "/Ego's%20Fire",
        "title": "Ego's Fire"
      }}>{`Ego's Fire`}</a>{` happened.`}</p>
    <p>{`Shani is quick with her words and even quicker with her blade. Both of her parents are devout `}<a parentName="p" {...{
        "href": "/The%20Argent",
        "title": "The Argent"
      }}>{`Argent`}</a>{` Missaries, and she was met with disappointment when she decided to pursue education at `}<a parentName="p" {...{
        "href": "/The%20Basentia%20Omnia",
        "title": "The Basentia Omnia"
      }}>{`The Basentia`}</a>{`. Still, she has a plan to become a rich and famous Acronist, and will do whatever it takes to acheive her goals.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      